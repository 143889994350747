@import "../../global/styles/config";

.Navigation {
  position: relative;
  width: 100%;
  padding: 0;

  &-innerWrapper {
    display: flex;
    background-color: $theme-primary;
    position: relative;
    z-index: $z-300;
  }

  &--rightAlign {
    display: inline-flex;
    margin: 0 $spacer-4 0 auto;
  }

  &-logo {
    margin: 0 auto;
    display: inline-flex;
  }
}

@media only screen and (min-width: $bp-m-px) {
  .Navigation {
    background-color: $theme-primary;

    &-innerWrapper {
      max-width: 90%;
      margin: auto;
    }

    &--rightAlign {
      margin: auto 0 auto auto;
    }

    &-logo {
      margin: 0;
    }
  }
}

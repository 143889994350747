@import "../../../../global/styles/config.scss";

.NavigationItem {
  display: block;
  color: $black;
  text-decoration: none;
  width: 100%;
  display: block;
  padding: $spacer-3 0;
  margin: $spacer-1 0;
  text-align: center;

  &-active {
    background-color: red;
  }

  &:hover {
    background-color: $theme-secondary;
  }
}

@media only screen and (min-width: $bp-m-px) {
  .NavigationItem {
    padding: $spacer-3 $spacer-4;
  }
}

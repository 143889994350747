@import "../global/styles/config.scss";

.App {
  display: block;
  position: relative;
  width: 90%;
  max-width: 1024px;
  margin: auto;
  z-index: $z-000;
}

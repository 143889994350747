@import "../../../styles/config";

.Patty {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 18px;
  height: 1px;
  background: $white;
  transform: rotate(0);
  transition: all $to-s-ms;
  content: "";

  &--top {
    top: 23px;
    transform: translateY(-3px);
  }

  &--bottom {
    bottom: 23px;
    transform: translateY(3px);
  }

  &-active {
    &.Patty {
      &--top {
        top: 24.5px;
        transform: rotate(45deg) translateY(0px);
      }

      &--bottom {
        bottom: 24.5px;
        transform: rotate(-45deg) translateY(0px);
      }
    }
  }
}

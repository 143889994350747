@import "../../../styles/config";

.Line {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 18px;
  height: 1px;
  background: $white;
  transform: rotate(0);
  transition: all $to-s-ms;
  content: "";
  bottom: 19.5px;
  transform: rotate(-45deg) translateY(0px);

  &--reversed {
    top: 19.5px;
    transform: rotate(45deg) translateY(0px);
  }
}

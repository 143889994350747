@import "../../../global/styles/config.scss";

.NavigationItems {
  display: block;
  position: absolute;
  width: 100%;
  margin: 0;
  padding: 0;
  bottom: 0;
  z-index: $z-200;
  background-color: $white;
  transition: transform $to-l-ms linear;
  // BEGIN: react-transition-group specific class declarations
  &-enter {
    transform: translateY(0);

    &-active {
      transform: translateY(100%);
    }

    &-done {
      transform: translateY(100%);
    }
  }

  &-exit {
    transform: translateY(100%);

    &-active {
      transform: translateY(0);
    }
  }
  // END: react-transition-group specific class declarations

  &:first-child {
    margin: 0 0 $spacer-1;
  }

  &:last-child {
    margin: $spacer-1 0 0;
  }
}

@media only screen and (min-width: $bp-m-px) {
  .NavigationItems {
    display: inline-flex;
    position: relative;
    width: auto;
    margin: 0 $spacer-1 0 $spacer-11;
    bottom: initial;
    z-index: $z-000;
    background-color: transparent;
    transform: none;
    transition: initial;

    &:last-child {
      margin: 0 0 0 $spacer-1;
      padding: 0;
    }

    &:first-child {
      margin: 0 $spacer-1 0 0;
      padding: 0;
    }
  }
}

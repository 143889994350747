/* roboto-100 - latin */
// @font-face {
//   font-family: "Roboto";
//   font-style: normal;
//   font-weight: 100;
//   src: local(""),
//     url("../assets/fonts/roboto-v20-latin-100.woff2") format("woff2")
//       /* Chrome 26+, Opera 23+, Firefox 39+ */,
//     url("../assets/fonts/roboto-v20-latin-100.woff") format("woff")
//       /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */;
// }
/* roboto-regular - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: local(""),
    url("../assets/fonts/roboto-v20-latin-regular.woff2") format("woff2")
      /* Chrome 26+, Opera 23+, Firefox 39+ */,
    url("../assets/fonts/roboto-v20-latin-regular.woff") format("woff")
      /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */;
}
/* roboto-700 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: local(""),
    url("../assets/fonts/roboto-v20-latin-700.woff2") format("woff2")
      /* Chrome 26+, Opera 23+, Firefox 39+ */,
    url("../assets/fonts/roboto-v20-latin-700.woff") format("woff")
      /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */;
}
/* open-sans-regular - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: local(""),
    url("../assets/fonts/open-sans-v18-latin-regular.woff2") format("woff2")
      /* Chrome 26+, Opera 23+, Firefox 39+ */,
    url("../assets/fonts/open-sans-v18-latin-regular.woff") format("woff")
      /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */;
}
/* open-sans-700 - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  src: local(""),
    url("../assets/fonts/open-sans-v18-latin-700.woff2") format("woff2")
      /* Chrome 26+, Opera 23+, Firefox 39+ */,
    url("../assets/fonts/open-sans-v18-latin-700.woff") format("woff")
      /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */;
}

html {
  font-size: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Open Sans", sans-serif;
  text-rendering: optimizeLegibility;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", sans-serif;
}

p,
label,
input,
textarea {
  font-size: 1em;
}

legend {
  font-size: 1.5em;
}

$spacer-1: 4px;
$spacer-2: 8px;
$spacer-3: 12px;
$spacer-4: 16px;
$spacer-5: 20px;
$spacer-6: 24px;
$spacer-7: 28px;
$spacer-8: 32px;
$spacer-9: 36px;
$spacer-10: 40px;
$spacer-11: 44px;
$spacer-12: 48px;

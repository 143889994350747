@import "global/styles/config";

.Veil {
  display: block;
  position: fixed;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $black;
  opacity: 0.5;
  z-index: $z-300;
  &--behindNavigationItems {
    z-index: $z-100;
  }
}

@import "../../styles/config";

.Button {
  display: block;
  color: $black;
  padding: $spacer-3 $spacer-5;
  margin: $spacer-1 auto;
  border-radius: $spacer-1;
  color: $black;
  background-color: $theme-secondary;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}

@media only screen and (min-width: $bp-m-px) {
  .Button {
    display: flex;
    position: relative;
    margin: auto;
    padding: $spacer-1 $spacer-5;
    height: $spacer-8;

    &-content {
      display: inline-flex;
      margin: auto;
    }
  }
}

@import "../../styles/config";

.X {
  position: absolute;
  align-items: center;
  top: -$spacer-10;
  right: -$spacer-10;
  width: $spacer-10;
  height: $spacer-10;
  margin: auto 0;
  z-index: $z-100;
  cursor: pointer;
  transition: all $to-s-ms;
  transform-origin: center;
}

@import "../../styles/config";

.Burger {
  position: absolute;
  align-items: center;
  top: 0;
  bottom: 0;
  width: 50px;
  height: 50px;
  margin: auto 0 auto 0;
  cursor: pointer;
  transition: all $to-s-ms;
  transform-origin: center;

  &-active {
    transform: rotate(90deg);
  }
}

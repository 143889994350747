$z-000: 000;
$z-100: 100;
$z-200: 200;
$z-300: 300;
$z-400: 400;
$z-500: 500;
$z-600: 600;
$z-700: 700;
$z-800: 800;
$z-900: 900;
$z-999: 999;
